/**
  Description:
  still needs variables selectAll, and selected: [] in parent component
**/
export var checkAll = {
  methods: {
    checkAll (data) {
      this.selectAll = !this.selectAll
      let check = data ? this.selected.length = data.length : false

      if (check) {
        if (this.selectAll) {
          this.selected = data.map( (d) => {
            return d.id
          })
        } else {
          this.selected = []
        }
      }
    }
  }
}
