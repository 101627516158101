import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,
    created_date: `期間`,
    updated_date: `修正日時`,
    deleted_date: `削除日時`,

    agent_name: `店舗名`,

    //= table
    date: `日付`,
    users: `ユーザー`,
    holding: `保有金額`,
    cash: `入/出 金額`,
    point: `ポイント`,
    bet: `ベッティング`,
    win: `勝ち`,
    commission: `コミッション`,

    title: `タイトル`,
    in: `in`,
    out: `out`,
    net: `利益`,

    total: `トータル`,
    new: `新規`,
    ban: `停止`,
    deleted: `削除`,

    amount: `保有金額`,
    point: `ポイント`,
    cash_back: `キャッシュバック`,

    cash: `入/出`,
    manual: `支給/回収`,
    conversion: `交換`,

    point_manual: `手動　支給`,
    point_auto: `自動支給`,
    point_event: `イベント`,
    point_conversion: `交換`,

    mini: `ミニ`,
    live: `ライブ`,
    agent: `店舗名`,
    referral: `推薦人`,

    cash_in: `入金`,
    cash_out: `出金`,
    cash_net: `入出収益`,
    manual_in: `支給`,
    manual_out: `回収`,
    bet_net: `ベッティング収益`,
    point_in: `ポイント支給`,
    point_in_auto: `ポイント自動支給`,
    point_in_manual: `ポイント手動支給`,
    point_out: `ポイント回収`,

    bet_users: `ベッティング会員`,
    new_users: `新規会員`,
    bet_cancel: `ベッティング取消`,

    named: `名前`,
    ntry: `エントリー`,
    livescore: `ライブスコア`,
    halfline: `ハーフライン`,
    sum: `合計`,
    all: `全体`,
    sports_free: `スポーツ`,
    sports_inplay: `インプレー`,
    mini_game: `ミニゲーム`,
    live_casino: `ライブカジノ`,
    casino: `カジノ`,
    virtual: `バーチャルゲーム`,
    slot_game: `スロットゲーム`,

    bet365_dog_golden: 'bet365 dog golden',
    bet365_dog_hillside: 'bet365 dog hillside',
    bet365_hose_festival: 'bet365 hose festival',
    bet365_soccer_premier: 'bet365 soccer premier',
    bet365_soccer_supper: 'bet365 soccer super',
    bet365_soccer_euro: 'bet365 soccer euro',
    bet365_hose_britanny: 'bet365 hose brittany',
    bet365_hose_virtoria: 'bet365 hose victoria'
  }
}
