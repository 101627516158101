import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',
    created_date: '기간',
    updated_date: '수정 일자',
    deleted_date: '삭제 일자',

    agent_name: '매장 이름',

    //= table
    date: '날짜',
    users: '유저 수',
    holding: '보유 금액',
    cash: '입/출 금액',
    point: '포인트',
    bet: '배팅',
    win: '당첨',
    commission: '커미션',

    title: '제목',
    in: 'IN',
    out: 'OUT',
    net: '수익',

    total: '전체',
    new: '신규',
    ban: '중지',
    deleted: '삭제',

    amount: '보유 금액',
    cash_back: '캐시벡',

    manual: '지급/회수',
    conversion: '전환',

    point_manual: '수동 지급',
    point_auto: '자동 지급',
    point_event: '이벤트',
    point_conversion: '포인트 전환',

    sports: '스포츠',
    mini: '미니',
    live: '라이브',
    agent: '매장',
    referral: '추천인',

    cash_in: '입금',
    cash_out: '출금',
    cash_net: '입출 수익',
    manual_in: '지급',
    manual_out: '회수',
    bet_net: '배팅 수익',
    point_in: '포인트 지급',
    point_in_auto: '포인트 자동',
    point_in_manual: '포인트 수동',
    point_out: '포인트 회수',
    bet_users: '배팅 회원',
    new_users: '신규 회원',
    bet_cancel: '배팅 취소',

    named: '네임드',
    ntry: '엔트리',
    livescore: '라이브스코어',
    halfline: '하프라인',
    sum: '합계',
    all: '전체',
    sports_free: '스포츠',
    sports_inplay: '인플레이',
    mini_game: '미니게임',
    live_casino: '라이브카지노',
    casino: '카지노게임',
    virtual: '버츄얼게임',
    slot_game: '슬롯게임',
    token: '토큰게임',

    mario: '마리오',
    tomstv: '톰스TV',

    BET365_DOG_GOLDEN: '개경주 GOLDEN',
    bet365_dog_hillside: '개경주 HILLSIDE',
    bet365_hose_festival: '경마 FESTIVAL',
    bet365_soccer_premier: '축구 PREMIER',
    bet365_soccer_supper: '축구 SUPER',
    bet365_soccer_euro: '축구 EURO',
    bet365_hose_britanny: '경마 BRITTANY',
    bet365_hose_virtoria: '경마 VICTORIA',

    bet_amount: '배팅 금액',
    bet_details: '배팅 상세 정보',
    game_name: '게임명',
    win_amount: '당첨 금액',
    agent_id: '매장 아이디',
    created_at: '생성일',

    bet_account: '배팅 정산',
    account: '정산',

    dog: '개경주',
    hose: '경마',
    soccer: '가상축구'
  }
}
