/* eslint-disable camelcase */

import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,
    created_date: `created`,
    updated_date: `updated`,
    deleted_date: `deleted`,

    agent_name: `agent name`,

    //= table
    date: `date`,
    users: `users`,
    holding: `holding`,
    cash: `cash`,
    point: `point`,
    bet: `bet`,
    win: `win`,
    commission: `commission`,

    title: `title`,
    in: `in`,
    out: `out`,
    net: `net`,

    total: `total`,
    new: `new`,
    ban: `ban`,
    deleted: `deleted`,

    amount: `amount`,
    point: `point`,
    cash_back: `cash back`,

    cash: `cash`,
    manual: `manual`,
    conversion: `conversion`,

    point_manual: `manual`,
    point_auto: `auto`,
    point_event: `event`,
    point_conversion: `conversion`,

    mini: `mini`,
    live: `live`,
    agent: `agent`,
    referral: `referral`,

    cash_in: `cash in`,
    cash_out: `cash out`,
    cash_net: `cash net`,
    manual_in: `manual in`,
    manual_out: `manual out`,
    bet_net: `bet net`,
    point_in: `point in`,
    point_in_auto: `point in auto`,
    point_in_manual: `point in manual`,
    point_out: `point out`,

    bet_users: `bet users`,
    new_users: `new users`,
    bet_cancel: `bet cancel`,

    named: `named`,
    ntry: `ntry`,
    livescore: `livescore`,
    halfline: `halfline`,
    sum: `sum`,
    all: `all`,
    sports_free: `sports free`,
    sports_inplay: `sports inplay`,
    mini_game: `mini game`,
    live_casino: `live casino`,
    casino: `casino`,
    virtual: `virtual`,
    slot_game: `slot game`,

    bet365_dog_golden: 'bet365 dog golden',
    bet365_dog_hillside: 'bet365 dog hillside',
    bet365_hose_festival: 'bet365 hose festival',
    bet365_soccer_premier: 'bet365 soccer premier',
    bet365_soccer_supper: 'bet365 soccer super',
    bet365_soccer_euro: 'bet365 soccer euro',
    bet365_hose_britanny: 'bet365 hose brittany',
    bet365_hose_virtoria: 'bet365 hose victoria'
  }
}
